import React from 'react';

import Layout from '../../components/Layout';
import IconTextGrid, {
  startingMyfembreeIconGrid
} from '../../components/IconTextGrid/IconTextGrid';
import InTheGroove, {
  inTheGrooveContent
} from '../../components/InTheGroove/InTheGroove';
import ForgetToTake from '../../components/ForgetToTake/ForgetToTake';
import FloatingLinks, {
  StartingMyfembreeFloatingLinkData
} from '../../components/FloatingLinks/FloatingLinks';
import KnockoutDriver from '../../components/KnockoutDriver/KnockoutDriver';

import Shield from '../../assets/svgs/Shield.svg';
import HowToStart from '../../components/HowToStart/HowToStart';
import Hero3, { readyToStart } from '../../components/Hero/Hero3';
import QuickLinks2, {
  QuickLinks2ReadyToStart
} from '../../components/QuickLinks2/QuickLinks2';
import GlobalStartingMyFembree from '../../components/GlobalStartingMyFembree/global-starting-myfembree';

// import StartingMFHero from '../../components/StartingMFHero/starting-mf-hero';
// import StartingMFHowTo from '../../components/StartingMFHowTo/starting-mf-how-to';
// import DoNotTake from '../../components/DoNotTake/do-not-take';
// import WhatIf from '../../components/WhatIf/what-if';
// import DoctorsGuidance from '../../components/DoctorsGuidance/doctors-guidance';
// import CommercialInsurance from '../../components/CommercialInsurance/commercial-insurance';

const Home = () => {
  const {
    refs: [headerRef, howToStartRef, forgetRef, inTheGrooveRef],
    floatingLinksProps
  } = StartingMyfembreeFloatingLinkData();
  return (
    <Layout section="EM" page="/endometriosis/starting-myfembree/">
      <>
        <GlobalStartingMyFembree />

        {/* <StartingMFHero /> */}

        {/* <StartingMFHowTo /> */}

        {/* <DoNotTake /> */}

        {/* <WhatIf /> */}

        {/* <DoctorsGuidance /> */}

        {/* <CommercialInsurance /> */}
      </>
    </Layout>
  );
};

export const Head = () => {
  const canonicalURL =
    'https://www.myfembree.com/endometriosis/starting-myfembree/';
  const title =
    'Starting MYFEMBREE® (relugolix, estradiol, and norethindrone acetate) for Endometriosis Pain | Safety Info';
  const pageTitle =
    'Starting MYFEMBREE® (relugolix, estradiol, and norethindrone acetate) for Endometriosis Pain | Safety Info';
  const description =
    'Learn how to get started on MYFEMBREE® for moderate to severe endometriosis pain in premenopausal women. See safety information & BOXED WARNING.';
  return (
    <>
      <meta charSet="utf-8" />
      <title>{title}</title>
      <meta name="title" content={pageTitle} />
      <meta name="description" content={description} />
      <link rel="canonical" href={canonicalURL} />
      <meta name="url" content={canonicalURL} />
      <html lang="en" />
    </>
  );
};

export default Home;
